import { useEffect } from "react";
import {
  getPersonStatus,
  postPersonEnrich,
} from "../../api/hooks/careerRoadmap";
import LoadingCard from "../../components/LoadingCard";
import { DASHBOARD, CURRICULUM_UPLOAD } from "../../routes/paths";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { PersonStatus } from "../../@types/api/careerRoadmap";

function CareerSyncPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const postPersonEnrichMutation = postPersonEnrich();

  const { data: { data: personStatus = {} as PersonStatus } = {} } =
    getPersonStatus();

  useEffect(() => {
    const { curriculum, enrichedData } = personStatus;
    const userDoesNotHaveEnrichedData =
      curriculum && enrichedData && !curriculum.exist && !enrichedData.exist;
    if (userDoesNotHaveEnrichedData) {
      postPersonEnrichMutation
        .mutateAsync()
        .then(({ data: { enrichedData: { exist = false } } = {} }) => {
          if (exist) {
            navigate(DASHBOARD);
          } else {
            navigate(CURRICULUM_UPLOAD);
          }
        })
        .catch(() => {
          toast.error(t("careerSync.error"));
        });
    }
  }, [personStatus]);

  return <LoadingCard slug={"careerSync"} />;
}

export default CareerSyncPage;
