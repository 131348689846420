import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as JobIcon } from "../../../../assets/job_icon.svg";
import { Line } from "../../../../components/Line";
import { Card } from "../../../../components/Card";
import { Skeleton } from "../../../../components/Skeleton";

export const CurrentJobCard = ({
  isLoading = false,
  currentJobTitle,
  yearsExperience,
  skills = [],
}: {
  isLoading?: boolean;
  currentJobTitle?: string;
  yearsExperience?: number;
  skills?: string[];
}) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        alignItems: "flex-start !important",
        width: "calc(360px - 60px)",
        height: "calc(550px - 48px)",
        padding: "24px 30px",
      }}
    >
      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        <JobIcon />
        <Typography fontWeight={"light"} variant="h6" ml={"7px"}>
          {t("currentJobCard.title")}
        </Typography>
      </Box>

      {currentJobTitle && (
        <Typography
          textTransform={"capitalize"}
          mt={"30px"}
          fontWeight={"bold"}
          variant="h6"
        >
          {currentJobTitle}
        </Typography>
      )}

      {yearsExperience && (
        <Typography mt={"9px"}>
          {t("currentJobCard.experience", { yearsExperience })}
        </Typography>
      )}

      <Line width={"360px"} ml={"-30px"} mt={"32px"} mb={"30px"}></Line>
      {isLoading ? (
        <Skeleton width={"100%"} height={"100%"} sx={{ transform: "none" }} />
      ) : (
        <>
          <Typography fontSize={"12px"} fontWeight={"light"} mb={"21px"}>
            {t("currentJobCard.skills")}
          </Typography>

          {skills.map((skill: string, index: number) => (
            <Box width={"100%"} key={skill}>
              <Typography textTransform={"capitalize"}>{skill}</Typography>
              {index !== skills.length - 1 && <Line my={"15px"}></Line>}
            </Box>
          ))}
        </>
      )}
    </Card>
  );
};
