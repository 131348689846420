import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ReactComponent as CareerRoadmapLogo } from "../../assets/careerroadmap_logo.svg";
import { LogoutButton, StyledAppBar, StyledToolbar } from "./styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { ACTIVE_PROFILE_PAGES } from "../../routes/paths";
import { getCareer, postSummary } from "../../api/hooks/careerRoadmap";
import { MyProfileSummary } from "./MyProfileSummary";
import { Button } from "../Button";
import useMatomo, { actions, pages } from "../../config/matomo/matomo";
import { toast } from "react-hot-toast";

function NavBar() {
  const { oktaAuth, authState } = useOktaAuth();
  const [loadingLogOut, setLoadingLogOut] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [openMyProfileSummary, setOpenMyProfileSummary] = useState(false);
  const { t } = useTranslation();
  const matomo = useMatomo();

  const { mutateAsync: postSummaryMutation } = postSummary();

  // summary button
  const showProfileSummary = ACTIVE_PROFILE_PAGES.includes(
    window.location.pathname
  );

  const {
    data: { data } = {},
    isLoading,
    refetch,
  } = getCareer(!!authState?.isAuthenticated && showProfileSummary);

  const postSummaryAndRefetch = async () => {
    try {
      setLoadingSummary(true);
      await postSummaryMutation();
      await refetch();
    } catch (error) {
      toast.error(t("error.postSummary"));
    } finally {
      setLoadingSummary(false);
    }
  };

  const handleOpenMyProfileSummary = async () => {
    matomo.trackEvent({
      category: pages.DASHBOARD_PAGE,
      action: actions.ACTION_CLICK_PROFILE_SUMARY,
    });
    if (!data?.profileSummary) {
      await postSummaryAndRefetch();
    }
    setOpenMyProfileSummary(true);
  };

  // logout
  const logout = async () => {
    setLoadingLogOut(true);
    oktaAuth
      .signOut()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingLogOut(false));
  };

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <CareerRoadmapLogo style={{ margin: "auto" }} />

        <Box flexGrow={1} />
        {showProfileSummary && !isLoading && (
          <Button
            loading={isLoading || loadingSummary}
            variant="outlined"
            onClick={handleOpenMyProfileSummary}
            sx={{
              marginRight: "17px",
            }}
          >
            {t("navBar.button.myProfileSummary")}
          </Button>
        )}
        {authState?.isAuthenticated && (
          <LogoutButton
            loading={loadingLogOut}
            startIcon={<LogoutIcon />}
            onClick={logout}
          >
            {t("navBar.button.logout")}
          </LogoutButton>
        )}

        <MyProfileSummary
          handleClose={setOpenMyProfileSummary}
          open={openMyProfileSummary}
          content={data?.profileSummary}
        />
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default NavBar;
