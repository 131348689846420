import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const TimelinePin = styled(Box)({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "6px solid #949BAA",
  height: "6px",
  width: "6px",
  borderRadius: "100%",
});

export const TimeLineDots = styled(Box)({
  flexGrow: 1,
  border: "1px dashed #949BAA",
});
