import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";
interface RedirectToLoginDialogProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}
export function RedirectToLoginDialog({
  isOpen,
  setIsOpen,
}: RedirectToLoginDialogProps) {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();

  const login = async () => {
    oktaAuth.signInWithRedirect().catch((err) => {
      console.log(err);
    });
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="register-dialog-title"
      aria-describedby="register-dialog-description"
    >
      <DialogTitle
        id="register-dialog-title"
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        {t("redirectToLogin.emailExists")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="register-dialog-description"
          sx={{ marginTop: "1rem" }}
        >
          {t("redirectToLogin.message")}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ paddingTop: 0, paddingBottom: "1rem", paddingRight: "1rem" }}
      >
        <Button variant="outlined" onClick={() => setIsOpen(false)}>
          {t("redirectToLogin.buttonBack")}
        </Button>
        <Box component="a" sx={{ marginLeft: "0.5rem" }}>
          <Button variant="contained" onClick={() => login()}>
            {t("redirectToLogin.buttonLogin")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
