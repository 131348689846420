import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const GreenBox = styled(Box)({
  border: "1px solid #24802F",
  borderRadius: "8px",
  padding: "15px",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-evenly",
});
