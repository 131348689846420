import { Box, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as JobIcon } from "../../../../assets/suggestions_icon.svg";
import { Card } from "../../../../components/Card";
import { Button } from "../../../../components/Button";
import useMatomo, { actions, pages } from "../../../../config/matomo/matomo";

export const CareerSuggestionCard = ({
  isLoadingPutMilestone = false,
  isLoading = false,
  nextMoves = [],
  selectRole = (role: string) => {
    console.log(`Selected ${role}`);
  },
}: {
  isLoadingPutMilestone?: boolean;
  isLoading?: boolean;
  nextMoves?: string[];
  selectRole?: (role: string) => void;
}) => {
  const { t } = useTranslation();
  const matomo = useMatomo();

  const handleSelectRole = (nextMove: string) => {
    selectRole(nextMove);
    matomo.trackEvent({
      category: pages.DASHBOARD_PAGE,
      action: actions.ACTION_CLICK_SELECT_JOB_ROLE,
      label: nextMove,
    });
  };

  return (
    <Card
      sx={{
        alignItems: "flex-start !important",
        width: "calc(470px - 60px)",
        height: "calc(550px - 48px)",
        padding: "24px 30px",
        overflowX: "hidden !important",
        overflowY: "auto !important",
      }}
    >
      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        <JobIcon />
        <Typography fontWeight={"light"} variant="h6" ml={"7px"}>
          {t("careerSuggestionCard.title")}
        </Typography>
      </Box>

      {isLoading ? (
        <Skeleton
          width={"100%"}
          height={"100%"}
          sx={{ transform: "none", mt: "15px" }}
        />
      ) : (
        <>
          <Typography
            fontSize={"12px"}
            mt={"30px"}
            fontWeight={"light"}
            mb={"15px"}
          >
            {t("careerSuggestionCard.suggestedJobs")}
          </Typography>

          {nextMoves.map((nextMove: string, index: number) => (
            <Box
              key={nextMove}
              width={"calc(100% - 30px)"}
              justifyContent={"space-between"}
              alignItems={"center"}
              display={"flex"}
              paddingY={"18px"}
              paddingX={"15px"}
              border={"1px solid #EDEDED"}
              borderRadius={"6px"}
              mb={index === nextMoves.length - 1 ? "0px" : "10px"}
            >
              <Typography textTransform={"capitalize"} key={index}>
                {nextMove}
              </Typography>
              <Button
                sx={{ minWidth: "110px", ml: 3 }} // Used 145px so the text doesn't break inside the button
                loading={isLoadingPutMilestone}
                onClick={() => handleSelectRole(nextMove)}
                variant="outlined"
              >
                {t("careerSuggestionCard.addToCareerButton")}
              </Button>
            </Box>
          ))}
        </>
      )}
    </Card>
  );
};
