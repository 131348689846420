import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Box } from "@mui/material";
import { TimeLineDots, TimelinePin } from "./styles";

export const Timeline = ({
  isLoadingCareerAdvice = false,
  setOpenMyCareerAdvice = () => console.log("setOpenMyCareerAdvice"),
}: {
  isLoadingCareerAdvice?: boolean;
  setOpenMyCareerAdvice?: any;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      maxWidth={"976px"}
      display={"flex"}
      alignItems={"center"}
      mt={"35px"}
      width={"100%"}
    >
      <TimelinePin />
      <TimeLineDots />
      <Button
        loading={isLoadingCareerAdvice}
        onClick={() => setOpenMyCareerAdvice(true)}
        variant="contained"
      >
        {t("timeline.careerAdviceSummaryButton")}
      </Button>
      <TimeLineDots />
      <TimelinePin />
    </Box>
  );
};
