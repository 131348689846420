import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/Login";

import { LoginCallback } from "@okta/okta-react";

import { RequiredAuth } from "../components/SecureRoute";
import Layout from "../layouts/Default";
import LoadingAuth from "../components/LoadingAuth";
import CareerSyncPage from "../pages/CareerSync";
import NotFoundPage from "../pages/NotFound";
import DashboardPage from "../pages/Dashboard";
import {
  CAREER_SYNC,
  HOME,
  LOGIN_CALLBACK,
  NOT_FOUND,
  DASHBOARD,
  USER_DATA_NOT_FOUND,
  LOADING_AUTH,
  REGISTER,
  REGISTER_DETACHED,
  CURRICULUM_UPLOAD,
} from "./paths";
import UserDataNotFoundPage from "../pages/UserDataNotFound";
import Register from "../pages/Register";
import RegisterDetached from "../pages/RegisterDetached";
import CurriculumUploadPage from "../pages/CurriculumUpload";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={HOME} element={<LoginPage />} />
        <Route
          path={LOGIN_CALLBACK}
          element={<LoginCallback loadingElement={<LoadingAuth />} />}
        />
        <Route path={REGISTER} element={<Register />} />
        <Route element={<RequiredAuth />}>
          {/* place components requiring protection here */}
          <Route path={REGISTER_DETACHED} element={<RegisterDetached />} />
          <Route path={CAREER_SYNC} element={<CareerSyncPage />} />
          <Route path={LOADING_AUTH} element={<LoadingAuth />} />
          <Route
            path={USER_DATA_NOT_FOUND}
            element={<UserDataNotFoundPage />}
          />
          <Route path={DASHBOARD} element={<DashboardPage />} />
          <Route path={CURRICULUM_UPLOAD} element={<CurriculumUploadPage />} />
        </Route>

        <Route path={NOT_FOUND} element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
