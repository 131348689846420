import styled from "@emotion/styled";
import { Card } from "../../../components/Card";
import { Box } from "@mui/material";

export const SideCard = styled(Card)({
  width: "360px",
  height: "550px",
});

export const MainCard = styled(Card)({
  width: "470px",
  height: "550px",
});

export const Chain = styled(Box)({
  flexGrow: 1,
  height: 0,
  display: "flex",
  justifyContent: "space-between",
  border: "1px dashed #949BAA",
  borderLeft: "none",
  borderRight: "none",
  "&::before": {
    content: "''",
    display: "block",
    width: "15px",
    height: "30px",
    transform: "translateY(-50%)",
    borderRadius: "0 15px 15px 0",
    backgroundColor: "#949BAA",
  },
  "&::after": {
    content: "''",
    display: "block",
    width: "15px",
    height: "30px",
    transform: "translateY(-50%)",
    borderRadius: "15px 0 0 15px",
    backgroundColor: "#949BAA",
  },
});
