import styled from "@emotion/styled";
import { AppBar, Toolbar } from "@mui/material";
import { Button } from "../../Button";

export const StyledAppBar = styled(AppBar)({
  height: "64px",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 2px 4px #00000014",
  justifyContent: "center",
  position: "fixed",
  zIndex: 100,
});

export const StyledToolbar = styled(Toolbar)({
  height: "100%",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  paddingRight: "0 !important",
});

export const LogoutButton = styled(Button)({
  color: "#000000",
  borderLeft: "1px solid #E0E0E0",
  borderRadius: 0,
  height: "100%",
  padding: "0 24px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});
