import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { useField } from "formik";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "../../routes/paths";

interface PrivacyPolicyCheckBoxProps {
  name: string;
}

const PrivacyPolicyCheckBox = (props: PrivacyPolicyCheckBoxProps) => {
  const [field, meta] = useField({
    name: props.name,
    type: "checkbox",
  });

  const label = (
    <Typography variant="body1">
      <Trans
        i18nKey="privacyPolicyCheckBox.label"
        components={{
          1: (
            <Link
              style={{ color: "#808080" }}
              target="_blank"
              to={TERMS_OF_SERVICE_LINK}
            />
          ),
          2: (
            <Link
              style={{ color: "#808080" }}
              target="_blank"
              to={PRIVACY_POLICY_LINK}
            />
          ),
        }}
      >
        {`PLACEHOLDER <1>PLACEHOLDER</1> PLACEHOLDER <2>placeholder</2>`}
      </Trans>
    </Typography>
  );
  const style =
    meta.touched && meta.error ? { color: "red" } : { color: "gray" };

  return (
    <Box style={{ marginTop: "20px" }}>
      <FormControlLabel
        control={<Checkbox {...field} />}
        label={label}
        style={style}
      />
      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          mt={"5px"}
          lineHeight={"20px"}
          style={{ color: "red" }}
        >
          {" "}
          {meta.error}{" "}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PrivacyPolicyCheckBox;
