import styled from "@emotion/styled";
import { Card as MuiCard } from "@mui/material";

export const Card = styled(MuiCard)({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 1px 38px #0000001A",
  borderRadius: "12px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
});
