import {
  ABOUT_CAREERROADMAP_LINK,
  ENTERPRISE_ALUMNI_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from "../../routes/paths";
import { Box, Typography } from "@mui/material";

import { ReactComponent as PoweredByEALogo } from "../../assets/powered_by_ea_logo.svg";
import { useTranslation } from "react-i18next";
import { FooterLink } from "./styles";

function Footer() {
  const { t } = useTranslation();

  const handleLogoClick = () => {
    window.open(ENTERPRISE_ALUMNI_LINK, "_blank");
  };
  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      mb={7}
      mt={5}
      width={"100%"}
    >
      <Typography mx={1} variant="caption">
        <FooterLink target="_blank" to={ABOUT_CAREERROADMAP_LINK}>
          {t("footer.aboutCareerRoadmap")}
        </FooterLink>
      </Typography>

      <Typography mx={1} variant="caption">
        <FooterLink target="_blank" to={TERMS_OF_SERVICE_LINK}>
          {t("footer.termsOfService")}
        </FooterLink>
      </Typography>

      <Typography mx={1} mr={5} variant="caption">
        <FooterLink target="_blank" to={PRIVACY_POLICY_LINK}>
          {t("footer.privacyPolicy")}
        </FooterLink>
      </Typography>

      <PoweredByEALogo
        style={{ cursor: "pointer" }}
        onClick={handleLogoClick}
      />
    </Box>
  );
}

export default Footer;
