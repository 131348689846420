import Modal from "../../Modal";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../../ModalHeader";
import { TextArea } from "../../TextArea";
import { ModalButtons } from "../../ModalButtons";

export const MyProfileSummary = ({
  open = false,
  handleClose = () => console.log("handleClose"),
  content,
}: {
  open?: boolean;
  handleClose?: any;
  content?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Modal width="636px" handleClose={() => handleClose(false)} open={open}>
      <ModalHeader
        mb="17"
        handleClose={() => handleClose(false)}
        title={t("modal.myProfileSummary.title")}
      />

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        {content && (
          <TextArea
            maxHeight={"440px"}
            variant="body2"
            backgroundType="light"
            mt={"25px"}
          >
            {content}
          </TextArea>
        )}
      </Box>
      <ModalButtons handleClose={handleClose} content={content} />
    </Modal>
  );
};
