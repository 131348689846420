import Modal from "../../Modal";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../../ModalHeader";
import { GreenBox } from "./styles";
import { ReactComponent as CareerAdviceIcon } from "../../../assets/careers_icon.svg";
import { TextArea } from "../../TextArea";
import { ModalButtons } from "../../ModalButtons";

export const MyCareerAdvice = ({
  open = false,
  handleClose = () => console.log("handleClose"),
  content,
}: {
  open?: boolean;
  handleClose?: any;
  content?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Modal width="636px" handleClose={() => handleClose(false)} open={open}>
      <ModalHeader
        handleClose={() => handleClose(false)}
        title={t("myCareerAdviceModal.title")}
      />

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <GreenBox>
          <CareerAdviceIcon
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />
          <Typography variant="body2" width={"calc(100% - 40px)"}>
            {t("myCareerAdviceModal.explanation")}
          </Typography>
        </GreenBox>
        {content && (
          <TextArea variant="body2" backgroundType="light" mt={3}>
            {content}
          </TextArea>
        )}
      </Box>
      <ModalButtons handleClose={handleClose} content={content} />
    </Modal>
  );
};
