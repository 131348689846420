import { useQuery, useMutation, UseQueryResult } from "@tanstack/react-query";
import { useAuthToken } from "../../utils/local-storage";
import { CareerRoadmapAPI } from "../endpoints/careerRoadmap";
import {
  GetUser,
  PostUser,
  PutUser,
  GetCareer,
  GetCompanies,
  PersonStatus,
} from "../../@types/api/careerRoadmap";
import { AxiosResponse } from "axios";

export const postSummary = () => {
  const Authorization = useAuthToken();

  return useMutation(["postSummary"], async () =>
    CareerRoadmapAPI.postSummary(Authorization)
  );
};

export const putSummary = () => {
  const Authorization = useAuthToken();

  return useMutation(["putSummary"], async (data: PutUser) =>
    CareerRoadmapAPI.putSummary(Authorization, data)
  );
};

export const getUser = (
  enabled = false
): UseQueryResult<AxiosResponse<GetUser>> => {
  const Authorization = useAuthToken();

  return useQuery(
    ["getUser"],
    async () => CareerRoadmapAPI.getUser(Authorization),
    { enabled }
  );
};

export const getCareer = (
  enabled = false
): UseQueryResult<AxiosResponse<GetCareer>> => {
  const Authorization = useAuthToken();

  return useQuery(
    ["getCareer"],
    async () => CareerRoadmapAPI.getCareer(Authorization),
    { enabled }
  );
};

export const putUser = () => {
  const Authorization = useAuthToken();

  return useMutation(["putUser"], async (data: PutUser) =>
    CareerRoadmapAPI.putUser(Authorization, data)
  );
};

export const postUser = () => {
  const Authorization = useAuthToken();

  return useMutation(["postUser"], async (data: PostUser) =>
    CareerRoadmapAPI.postUser(Authorization, data)
  );
};

export const postCareerSkills = () => {
  const Authorization = useAuthToken();

  return useMutation(["postCareerSkills"], async () =>
    CareerRoadmapAPI.postCareerSkills(Authorization)
  );
};

export const postCareerNextMoves = () => {
  const Authorization = useAuthToken();

  return useMutation(["postCareerNextMoves"], async () =>
    CareerRoadmapAPI.postCareerNextMoves(Authorization)
  );
};

export const putMilestone = () => {
  const Authorization = useAuthToken();

  return useMutation(["putMilestone"], async (data: any) =>
    CareerRoadmapAPI.putMilestone(Authorization, data)
  );
};

export const postSkillsToDevelop = () => {
  const Authorization = useAuthToken();

  return useMutation(["postSkillsToDevelop"], async () =>
    CareerRoadmapAPI.postSkillsToDevelop(Authorization)
  );
};

export const postInterviewQuestions = () => {
  const Authorization = useAuthToken();

  return useMutation(["postInterviewQuestions"], async () =>
    CareerRoadmapAPI.postInterviewQuestions(Authorization)
  );
};

export const postCoverLetter = () => {
  const Authorization = useAuthToken();

  return useMutation(["postCoverLetter"], async () =>
    CareerRoadmapAPI.postCoverLetter(Authorization)
  );
};

export const postCareerAdvice = () => {
  const Authorization = useAuthToken();

  return useMutation(["postCareerAdvice"], async () =>
    CareerRoadmapAPI.postCareerAdvice(Authorization)
  );
};

export const getPersonStatus = (
  enabled = true
): UseQueryResult<AxiosResponse<PersonStatus>> => {
  const Authorization = useAuthToken();

  return useQuery(
    ["getPersonStatus"],
    async () => CareerRoadmapAPI.getPersonStatus(Authorization),
    { enabled }
  );
};

export const postPersonEnrich = () => {
  const Authorization = useAuthToken();

  return useMutation(["postPersonEnrich"], async () =>
    CareerRoadmapAPI.postPersonEnrich(Authorization)
  );
};

export const getCompanies = (): UseQueryResult<AxiosResponse<GetCompanies>> => {
  return useQuery(["getCompanies"], async () =>
    CareerRoadmapAPI.getCompanies()
  );
};

export const postFileUploadUrlCurriculum = () => {
  const Authorization = useAuthToken();

  return useMutation(["postFileUploadUrlCurriculum"], async () =>
    CareerRoadmapAPI.postFileUploadUrlCurriculum(Authorization)
  );
};

export const postPersonCurriculumLoad = () => {
  const Authorization = useAuthToken();

  return useMutation(["postPersonCurriculumLoad"], async () =>
    CareerRoadmapAPI.postPersonCurriculumLoad(Authorization)
  );
};
