import * as Yup from "yup";

export const schema = (t = (key: string) => key) =>
  Yup.object({
    firstName: Yup.string()
      .max(15, t("register.form.firstName.max"))
      .required(t("register.form.required")),
    lastName: Yup.string()
      .max(20, t("register.form.lastName.max"))
      .required(t("register.form.required")),
    email: Yup.string()
      .email(t("register.form.email.invalid"))
      .required(t("register.form.required")),
    company: Yup.string().required(t("register.form.required")),
    password: Yup.string()
      .required(t("register.form.required"))
      .min(8, t("register.form.password.min"))
      .minLowercase(1, t("register.form.password.lowercase"))
      .minUppercase(1, t("register.form.password.uppercase"))
      .minNumbers(1, t("register.form.password.numbers")),
    confirmPassword: Yup.string()
      .required(t("register.form.required"))
      .oneOf([Yup.ref("password")], t("register.form.password.match")),
    acceptedPrivacyPolicy: Yup.boolean()
      .required(t("register.form.required"))
      .oneOf([true], t("register.form.acceptedPrivacyPolicy")),
  });
