import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { getCompanies } from "../../../../api/hooks/careerRoadmap";

interface CompanyComboBoxProps {
  label: string;
  fieldName: string;
}

const CompanyComboBox = ({ label, fieldName }: CompanyComboBoxProps) => {
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;

  const { data, isLoading: isLoadingCompanies } = getCompanies();
  const { data: companiesData } = data || {};

  const [companyOptions, setCompanyOptions] = useState<string[]>([]);

  useEffect(() => {
    if (companiesData) {
      setCompanyOptions(
        companiesData?.names && companiesData.names.length > 0
          ? companiesData.names
          : []
      );
    } else {
      setCompanyOptions([]);
    }
  }, [companiesData]);

  return (
    <>
      <Autocomplete
        disablePortal
        loading={isLoadingCompanies}
        options={companyOptions}
        freeSolo={true}
        onChange={(e, value) => {
          setValue(value);
        }}
        renderInput={(params: any) => (
          <TextField
            type="text"
            label={label}
            {...field}
            {...params}
            fullWidth
            variant={"outlined"}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoadingCompanies ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            size="small"
            style={{ marginTop: "20px" }}
          />
        )}
      />

      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          mt={"5px"}
          lineHeight={"20px"}
          style={{ color: "red" }}
        >
          {" "}
          {meta.error}{" "}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyComboBox;
