import { Button } from "../Button";
import Modal from "../Modal";
import { ModalHeader } from "../ModalHeader";
import Lottie from "lottie-react";
import feedbackAnimation from "../../assets/feedback-animation.json";
import RateReviewIcon from "@mui/icons-material/RateReview";

import { TextArea } from "../TextArea";
import { useTranslation } from "react-i18next";
import { FEEDBACK_FORM_LINK } from "../../routes/paths";
import { useEffect } from "react";
import useMatomo, { actions, pages } from "../../config/matomo/matomo";

function TooManyRequestsModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const matomo = useMatomo();

  const handleClick = () => {
    window.open(FEEDBACK_FORM_LINK, "_blank");
    matomo.trackEvent({
      category: pages.DASHBOARD_PAGE,
      action: actions.ACTION_CLICK_RATE_LIMIT_MODAL_FEEDBACK,
    });
  };

  useEffect(() => {
    if (open) {
      matomo.trackEvent({
        category: pages.DASHBOARD_PAGE,
        action: actions.ACTION_CLICK_RATE_LIMIT_MODAL,
      });
    }
  }, [open]);

  return (
    <Modal handleClose={handleClose} width="636px" open={open}>
      <ModalHeader
        mb="0px"
        title={t("tooManyRequestsModal.title")}
        handleClose={handleClose}
      />
      <Lottie
        style={{ height: "150px" }}
        animationData={feedbackAnimation}
        loop={true}
      />
      <TextArea variant="body2" backgroundType="light">
        {t("tooManyRequestsModal.text")}
      </TextArea>
      <Button onClick={handleClick} sx={{ mt: 2 }} variant="contained">
        <RateReviewIcon sx={{ mr: 1 }} />
        {t("tooManyRequestsModal.button")}
      </Button>
    </Modal>
  );
}

export default TooManyRequestsModal;
