import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

export const ModalHeader = ({
  title,
  subtitle,
  handleClose = () => console.log("handleClose"),
  mb = "32px", // {4}
}: {
  title?: string | any;
  subtitle?: string;
  handleClose?: any;
  mb?: string;
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width="100%"
      marginBottom={mb}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <Typography
          variant="h6"
          sx={{ mb: 2, paddingRight: "5px" }}
          fontWeight={"bold"}
        >
          {title}
          {subtitle && ":"}
        </Typography>
        {subtitle && <Typography variant="h6">{subtitle}</Typography>}
      </Box>

      <IconButton
        onClick={handleClose}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: -2,
        }}
      >
        <CloseOutlined />
      </IconButton>
    </Box>
  );
};
