import { useEffect } from "react";

interface MatomoConfig {
  url: string;
  siteId: string;
}

export const pages = {
  LANDING_PAGE: "Landing Page",
  DASHBOARD_PAGE: "Dashboard Page",
};

export const actions = {
  ACTION_CLICK_SIGN_IN: "Sign-in",
  ACTION_CLICK_REGISTER: "Register",
  ACTION_CLICK_PROFILE_SUMARY_CONFIRMATION: "Confirm Profile Summary",
  ACTION_CLICK_PROFILE_SUMARY: "View Profile Summary",
  ACTION_CLICK_CAREER_ADVICE: "View Career Advice Summary",
  ACTION_CLICK_SELECT_JOB_ROLE: "Select Job role",
  ACTION_CLICK_COVER_LETTER: "View CV Cover Letter",
  ACTION_CLICK_COVER_LETTER_COPY: "Copy CV Cover Letter",
  ACTION_CLICK_INTERVIEW_QUESTIONS: "View Interview Questions",
  ACTION_CLICK_INTERVIEW_QUESTIONS_COPY: "Copy Interview Questions",
  ACTION_CLICK_FEEDBACK: "Feedback",
  ACTION_CLICK_RATE_LIMIT_MODAL: "View Rate Limit Modal",
  ACTION_CLICK_RATE_LIMIT_MODAL_FEEDBACK: "Feedback on modal",
};

interface TrackEventConfig {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

const initializeMatomo = (config: MatomoConfig) => {
  if (!(window as any)._paq) {
    (window as any)._paq = [];
    (window as any)._paq.push(["setTrackerUrl", `${config.url}/matomo.php`]);
    (window as any)._paq.push(["setSiteId", config.siteId]);
    (window as any)._paq.push(["trackPageView"]);

    const d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.async = true;
    g.defer = true;
    g.src = `${config.url}/matomo.js`;
    s.parentNode?.insertBefore(g, s);
  }
};

const trackEvent = (config: TrackEventConfig) => {
  const { category, action, label, value } = config;
  (window as any)._paq = (window as any)._paq || [];
  (window as any)._paq.push(["trackEvent", category, action, label, value]);
};

const useMatomo = () => {
  useEffect(() => {
    const matomoConfig: MatomoConfig = {
      url: process.env.REACT_APP_MATOMO_URL || "",
      siteId: process.env.REACT_APP_MATOMO_SITE_ID || "",
    };

    if (matomoConfig.url && matomoConfig.siteId) {
      initializeMatomo(matomoConfig);
    } else {
      console.error(
        "Matomo configuration is incomplete. Please check your environment variables."
      );
    }

    return () => {
      (window as any)._paq = (window as any)._paq || [];
      (window as any)._paq.push(["trackPageView"]);
    };
  }, []);

  return {
    trackEvent,
  };
};

export default useMatomo;
