import { useTranslation } from "react-i18next";
import { Line } from "../../components/Line";
import CardPage from "../../components/CardPage";
import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";
import { RedAlertIcon } from "../../components/Icon";

function UserDataNotFoundPage() {
  const { t } = useTranslation();

  return (
    <CardPage title={t(`userDataNotFound.tabName`)}>
      <LogoSimple />
      <Typography fontWeight="light" mt={4} variant="h5">
        {t(`userDataNotFound.title`)}
      </Typography>

      <Line mt={"30px"} />

      <Box
        mt={"60px"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <RedAlertIcon />

        <Typography mx={"10px"} fontWeight="bold" variant="h6">
          {t(`userDataNotFound.error.title`)}
        </Typography>
      </Box>

      <Typography my={"18px"} width="100%">
        {t(`userDataNotFound.error.message`)}
      </Typography>
    </CardPage>
  );
}

export default UserDataNotFoundPage;
