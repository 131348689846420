import styled from "@emotion/styled";
import { LoadingButton as MuiButton } from "@mui/lab";

export const Button = styled(MuiButton)(
  ({ variant }: { variant?: "outlined" | "text" | "contained" | "link" }) => ({
    borderRadius: 21,
    textTransform: "none",
    fontWeight: "300",
    textDecoration: variant === "link" ? "underline" : "none",
    ":hover": variant === "link" ? { textDecoration: "underline" } : {},
  })
);
