import { Helmet } from "react-helmet";
import { ReactComponent as NotFoundIllustration } from "../../assets/404_illustration.svg";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router";

function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet title={`CareerRoadmap - ${t("notFoundPage.tabName")}`} />
      <NotFoundIllustration style={{ marginTop: "164px" }} />
      <Typography variant="h5" mt={"42px"}>
        {t("notFoundPage.title")}
      </Typography>
      <Typography variant="body2" mt={"9px"}>
        {t("notFoundPage.subtitle1")}
      </Typography>
      <Typography variant="body2">{t("notFoundPage.subtitle2")}</Typography>

      <Button
        sx={{ mt: "40px" }}
        onClick={() => navigate("/")}
        variant="contained"
      >
        {t("notFoundPage.homeButton")}
      </Button>
    </>
  );
}

export default NotFoundPage;
