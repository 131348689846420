import styled from "@emotion/styled";
import { Typography } from "@mui/material";

interface StyledTextAreaProps {
  backgroundType?: string;
  width?: string;
  maxHeight?: string;
}

export const TextArea = styled(Typography)<StyledTextAreaProps>(
  ({
    width = "calc(100% - 24px)",
    maxHeight = "100%",
    backgroundType = "default",
  }) => {
    let backgroundColor = "#F0F2F4"; // gray default color
    let border = "none";

    switch (backgroundType) {
      case "light":
        backgroundColor = "white";
        border = "1px solid #E0E0E0";
        break;
    }

    return {
      whiteSpace: "pre-line",
      background: `${backgroundColor} 0% 0% no-repeat padding-box`,
      borderRadius: "9px",
      padding: "12px",
      border,
      overflowY: "auto",
      maxHeight,
      width,
    };
  }
);
