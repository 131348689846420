import { Security } from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";

import AppRoutes from "./routes";
import { HOME, LOGIN_CALLBACK } from "./routes/paths";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import TooManyRequestsModal from "./components/TooManyRequestsModal";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_AUTH_ISSUER || "",
  clientId: process.env.REACT_APP_OKTA_AUTH_CLIENTID || "",
  redirectUri: window.location.origin + LOGIN_CALLBACK,
});

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(originalUri || HOME);
  };

  const [tooManyRequestsOpen, setTooManyRequestsOpen] = useState(false);

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: any) =>
        toast.error(`Something went wrong: ${error.message}`),
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        if (error.response.status === 429) {
          setTooManyRequestsOpen(true);
        } else {
          toast.error(`Something went wrong: ${error.message}`);
        }
      },
    }),
  });

  useEffect(() => {
    console.log(`
     ______          
    |  ____|   /\\    
    | |__     /  \\   
    |  __|   / /\\ \\  
    | |____ / ____ \\ 
    |______/_/    \\_\\
    Environment: ${process.env.REACT_APP_NODE_ENV}
    Build: ${process.env.REACT_APP_BUILD}
                     `);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AppRoutes />
      </Security>
      <TooManyRequestsModal
        open={tooManyRequestsOpen}
        handleClose={() => setTooManyRequestsOpen(false)}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export default App;
