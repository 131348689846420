export const useAuthToken = () => {
  const oktaTokenStorage = localStorage.getItem("okta-token-storage");
  if (!oktaTokenStorage) {
    console.error("okta-token-storage is not found");
    return undefined;
  }
  const { accessToken } = JSON.parse(oktaTokenStorage);

  if (!accessToken && !accessToken?.tokenType && !accessToken?.accessToken) {
    console.error("accessToken is not found");
    return undefined;
  }

  return `${accessToken?.tokenType} ${accessToken?.accessToken}`;
};

export const getFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  if (!value) {
    console.error(`${key} is not found`);
    return "";
  }
  return value;
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
