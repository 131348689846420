import { Box, Fab, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Timeline } from "../../components/Timeline";
import { Chain } from "./styles";
import {
  getCareer,
  getUser,
  postCareerNextMoves,
  postCareerSkills,
  putMilestone,
  postSkillsToDevelop,
  postCareerAdvice,
} from "../../api/hooks/careerRoadmap";
import { CurrentJobCard } from "./components/CurrentJobCard";
import { CareerSuggestionCard } from "./components/CareerSuggestionsCard";
import { MyCareerAdvice } from "../../components/DashboardModals/MyCareerAdvice";
import { useEffect, useState } from "react";
import { MilestoneCard } from "./components/MilestoneCard";
import { toast } from "react-hot-toast";
import HelpMeGetThisJobModal from "../../components/DashboardModals/HelpMeGetThisJob";
import RateReviewIcon from "@mui/icons-material/RateReview";
import useMatomo, { actions, pages } from "../../config/matomo/matomo";
import { FEEDBACK_FORM_LINK } from "../../routes/paths";

const DashboardPage = () => {
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [nextMoves, setNextMoves] = useState([]);
  const [skillsToDevelop, setSkillsToDevelop] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");

  const [openMyCareerAdvice, setOpenMyCareerAdvice] = useState(false);
  const [openHelpMeGetThisJob, setOpenHelpMeGetThisJob] = useState(false);
  const [isLoadingCareerAdvice, setIsLoadingCareerAdvice] = useState(false);
  const [isLoadingSelectRole, setIsLoadingSelectRole] = useState(false);

  const { mutateAsync: postCareerSkillsMutation, isLoading: isLoadingSkills } =
    postCareerSkills();

  const {
    mutateAsync: postCareerNextMovesMutation,
    isLoading: isLoadingNextMoves,
  } = postCareerNextMoves();

  const { mutateAsync: putMilestoneMutation } = putMilestone();

  const { mutateAsync: postSkillsToDevelopMutation } = postSkillsToDevelop();

  const { mutateAsync: postCareerAdviceMutation } = postCareerAdvice();

  const { data: { data: userData } = {} } = getUser(true);
  const { firstName } = userData || {};

  const { data: { data: careerData } = {}, refetch: refetchCareer } =
    getCareer(true);

  const {
    currentJobTitle,
    yearsExperience,
    advice,
    skills: unparsedSkills = "[]",
    nextMoves: unparsedNextMoves = "[]",
    careerMilestones = [],
  } = careerData || {};

  const {
    jobTitle: milestoneJobTitle,
    skillsToDevelop: unparsedSkillsToDevelop,
    coverLetter,
    interviewQuestions,
  } = careerMilestones[0] || {};

  const pageCategory = pages.DASHBOARD_PAGE;
  const matomo = useMatomo();

  const handleFeedbackClick = () => {
    matomo.trackEvent({
      category: pages.DASHBOARD_PAGE,
      action: actions.ACTION_CLICK_FEEDBACK,
    });
    window.open(FEEDBACK_FORM_LINK, "_blank");
  };

  // The AI used sometimes returns an array with single quotes instead of double quotes
  // so this string is not parseable by JSON.parse
  // this function replaces all single quotes with double quotes
  // and then parses the array
  const aiArrayFix = (array: string) => {
    try {
      return JSON.parse(array);
    } catch (error) {
      try {
        const newArray = array.replace(/'/g, '"');
        return JSON.parse(newArray);
      } catch (error) {
        console.error("Unable to fix the AI array");
        return [];
      }
    }
  };

  async function fetchSkills() {
    try {
      const { data: { skills = "[]" } = {} } = await postCareerSkillsMutation();
      return aiArrayFix(skills);
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    const isSkillsNotPopulatedByAI =
      (unparsedSkills === "[]" || !unparsedSkills) && !!careerData;
    async function getSkills() {
      const skills = isSkillsNotPopulatedByAI
        ? await fetchSkills()
        : aiArrayFix(unparsedSkills);
      setSkills(skills);
    }
    getSkills();
  }, [unparsedSkills, !!careerData]);

  async function fetchNextMoves() {
    try {
      const { data: { nextMoves = "[]" } = {} } =
        await postCareerNextMovesMutation();
      return aiArrayFix(nextMoves);
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    const isNextMovesNotPopulatedByAI =
      (unparsedNextMoves === "[]" || !unparsedNextMoves) && !!careerData;
    async function getNextMoves() {
      const nextMoves = isNextMovesNotPopulatedByAI
        ? await fetchNextMoves()
        : aiArrayFix(unparsedNextMoves);
      setNextMoves(nextMoves);
    }
    getNextMoves();
  }, [unparsedNextMoves, !!careerData]);

  useEffect(() => {
    if (unparsedSkillsToDevelop) {
      setSkillsToDevelop(aiArrayFix(unparsedSkillsToDevelop));
    }
  }, [unparsedSkillsToDevelop]);

  const onSelectRole = async (role: string) => {
    setIsLoadingSelectRole(true);
    try {
      setSelectedRole(role);
      await putMilestoneMutation({ jobTitle: role, position: 0 });
      await postSkillsToDevelopMutation();
      await refetchCareer();
    } catch (error: any) {
      setSelectedRole("");
      if (error.response.status !== 429) {
        toast.error(
          `Something went wrong while selecting new Job Role: ${role}`
        );
      }
    } finally {
      setIsLoadingSelectRole(false);
    }
  };

  const handleCareerAdviceClick = async () => {
    matomo.trackEvent({
      category: pageCategory,
      action: actions.ACTION_CLICK_CAREER_ADVICE,
    });
    setIsLoadingCareerAdvice(true);
    try {
      if (!advice) {
        await postCareerAdviceMutation();
        await refetchCareer();
      }
      setOpenMyCareerAdvice(true);
    } catch (error) {
      toast.error(`Something went wrong while generating career advice`);
    } finally {
      setIsLoadingCareerAdvice(false);
    }
  };

  return (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"1320px"}
    >
      <Typography
        mt={"35px"}
        fontSize={"24px"}
        fontWeight={"light"}
        variant={"h1"}
      >
        <Trans
          i18nKey="dashboardPage.welcome"
          components={{ 1: <b /> }}
          values={{ firstName }}
        >
          {`placeholder <1>{{ firstName }}</1> placeholder`}
        </Trans>
      </Typography>

      <Timeline
        isLoadingCareerAdvice={isLoadingCareerAdvice}
        setOpenMyCareerAdvice={handleCareerAdviceClick}
      />
      <Box
        mt={"30px"}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <CurrentJobCard
          isLoading={isLoadingSkills}
          currentJobTitle={currentJobTitle}
          yearsExperience={yearsExperience}
          skills={skills}
        />
        <Chain />
        <CareerSuggestionCard
          isLoadingPutMilestone={isLoadingSelectRole}
          isLoading={isLoadingNextMoves}
          nextMoves={nextMoves}
          selectRole={onSelectRole}
        />
        <Chain />
        <MilestoneCard
          isLoading={isLoadingSelectRole}
          onClickHelpMeGetThisJob={() => setOpenHelpMeGetThisJob(true)}
          jobTitle={selectedRole ? selectedRole : milestoneJobTitle}
          skills={skillsToDevelop}
        />
      </Box>

      <MyCareerAdvice
        handleClose={setOpenMyCareerAdvice}
        open={openMyCareerAdvice}
        content={advice}
      />

      <HelpMeGetThisJobModal
        handleClose={setOpenHelpMeGetThisJob}
        interviewQuestions={interviewQuestions}
        open={openHelpMeGetThisJob}
        milestoneJobTitle={milestoneJobTitle}
        coverLetter={coverLetter}
      />
      <Fab
        onClick={() => {
          handleFeedbackClick();
        }}
        color="primary"
        sx={{ position: "fixed", bottom: 50, right: 50 }}
        variant="extended"
      >
        <RateReviewIcon sx={{ mr: 1 }} />
        {t("dashboardPage.feedback")}
      </Fab>
    </Box>
  );
};

export default DashboardPage;
