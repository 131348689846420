import { useOktaAuth } from "@okta/okta-react";
import CardPage from "../../components/CardPage";
import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useState } from "react";
import LoadingAuth from "../../components/LoadingAuth";
import AccessPassportFooter from "../../components/AccessPassportFooter";
import { Navigate, useNavigate } from "react-router";
import { LOADING_AUTH, REGISTER } from "../../routes/paths";
import useMatomo, { actions, pages } from "../../config/matomo/matomo";

const LoginPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matomo = useMatomo();

  const login = async () => {
    matomo.trackEvent({
      category: pages.LANDING_PAGE,
      action: actions.ACTION_CLICK_SIGN_IN,
    });
    setLoadingLogin(true);
    oktaAuth
      .signInWithRedirect()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingLogin(false));
  };

  const register = () => {
    matomo.trackEvent({
      category: pages.LANDING_PAGE,
      action: actions.ACTION_CLICK_REGISTER,
    });
    setLoadingLogin(true);
    navigate(REGISTER);
  };

  if (!authState) {
    return <LoadingAuth />;
  }

  if (!!authState?.isAuthenticated) {
    return <Navigate to={LOADING_AUTH} />;
  }

  return (
    <CardPage title={t("loginPage.tabName")}>
      <LogoSimple />

      <Typography fontWeight={300} mt={4} variant="h5">
        {t("loginPage.title")}
      </Typography>

      <Typography mt={"20px"} fontWeight={600} variant="h6">
        {t("loginPage.subtitle")}
      </Typography>

      <Box height={1} mt={4} width={"60%"} borderBottom={"1px solid #E0E0E0"} />

      <Button
        loading={loadingLogin}
        sx={{ mt: 4 }}
        onClick={login}
        fullWidth
        variant="contained"
      >
        {t("loginPage.loginButton")}
      </Button>

      <Button
        sx={{ mt: "20px" }}
        fullWidth
        variant="outlined"
        onClick={register}
      >
        {t("loginPage.registerButton")}
      </Button>
      <AccessPassportFooter />
    </CardPage>
  );
};

export default LoginPage;
