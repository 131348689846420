import { useTranslation } from "react-i18next";
import CardPage from "../CardPage";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";

const LoadingCard = ({ slug }: { slug: string }) => {
  const { t } = useTranslation();

  return (
    <CardPage title={t(`${slug}.tabName`)}>
      <LogoSimple />
      <Typography fontWeight={300} mt={4} variant="h5">
        {t(`${slug}.title`)}
      </Typography>

      <Typography mt={"20px"} fontWeight={600} variant="h6">
        {t(`${slug}.subtitle`)}
      </Typography>

      <Box
        height={1}
        mt={4}
        mb={4}
        width={"60%"}
        borderBottom={"1px solid #E0E0E0"}
      />

      <CircularProgress size={"75px"} />
      <Typography mt={5} variant="body2">
        {t(`${slug}.pleaseWait`)}
      </Typography>
    </CardPage>
  );
};

export default LoadingCard;
