import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";
import CardPage from "../../components/CardPage";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import AccessPassportFooter from "../../components/AccessPassportFooter";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);
import TextInput from "../../components/TextInput";
import PrivacyPolicyCheckBox from "../../components/TermsConditionsCheckBox";
import { postUser } from "../../api/hooks/careerRoadmap";
import { PostUser } from "../../@types/api/careerRoadmap";
import { useNavigate } from "react-router";
import { CAREER_SYNC } from "../../routes/paths";
import { useEffect, useState } from "react";
import LoadingCard from "../../components/LoadingCard";
import { toast } from "react-hot-toast";
import CompanyComboBox from "./components/CompanyComboBox";
import { RedirectToLoginDialog } from "./components/RedirectToLoginDialog";
import { schema } from "./schema";
import { AxiosError } from "axios";

const Register = () => {
  const navigate = useNavigate();
  const postUserMutation = postUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (postUserMutation.isError) {
      //Case user already exists
      if ((postUserMutation?.error as AxiosError)?.response?.status === 409) {
        setLoading(false);
        setOpenDialog(true);
        return;
      } else {
        toast.error(`Something went wrong while registering`);
        return;
      }
    }
    if (postUserMutation.isSuccess) {
      setLoading(false);
      navigate(CAREER_SYNC);
    }
  }, [JSON.stringify(postUserMutation)]);

  return (
    <>
      {loading ? (
        <LoadingCard slug="register" />
      ) : (
        <CardPage title={t("register.tabName")}>
          <LogoSimple />

          <Typography fontWeight={300} mt={4} variant="h5">
            {t("register.title")}
          </Typography>

          <Typography mt={"20px"} fontWeight={600} variant="h6">
            {t("register.subtitle")}
          </Typography>

          <Box
            height={1}
            mt={4}
            width={"60%"}
            borderBottom={"1px solid #E0E0E0"}
          />

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              company: "",
              password: "",
              confirmPassword: "",
              acceptedPrivacyPolicy: false,
            }}
            validationSchema={() => schema(t)}
            onSubmit={(values, { setSubmitting }) => {
              setLoading(true);
              const data: PostUser = {
                ...values,
                email: values.email.toLowerCase(),
              };
              postUserMutation.mutate(data);
              setSubmitting(false);
            }}
          >
            <Form style={{ width: "100%" }}>
              <TextInput
                label={t("register.firstName")}
                fieldName="firstName"
                type="text"
              />

              <TextInput
                label={t("register.lastName")}
                fieldName="lastName"
                type="text"
              />

              <TextInput
                label={t("register.emailAddress")}
                fieldName="email"
                type="text"
              />

              <CompanyComboBox
                label={t("register.currentOrLastCompany")}
                fieldName="company"
              />

              <TextInput
                label={t("register.password")}
                fieldName="password"
                type="password"
              />

              <TextInput
                label={t("register.confirmPassword")}
                fieldName="confirmPassword"
                type="password"
              />

              <PrivacyPolicyCheckBox name="acceptedPrivacyPolicy" />

              <Button
                type="submit"
                loading={false}
                sx={{ mt: 5 }}
                fullWidth
                variant="contained"
              >
                {t("register.registerButton")}
              </Button>
            </Form>
          </Formik>

          <AccessPassportFooter />
        </CardPage>
      )}
      <RedirectToLoginDialog isOpen={openDialog} setIsOpen={setOpenDialog} />
    </>
  );
};

export default Register;
