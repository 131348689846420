import styled from "@emotion/styled";
import { Card } from "../../Card";

export const ModalCard = styled(Card)(
  ({ width = "384px" }: { width?: string }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: `calc(${width} - 64px)`,
    maxWidth: "100%",
    padding: "32px",
  })
);
