import { Box } from "@mui/material";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import useMatomo from "../../config/matomo/matomo";

export const ModalButtons = ({
  trackCopyEventPage,
  trackAction,
  trackLabel,
  content,
  handleClose,
}: {
  trackCopyEventPage?: string;
  trackAction?: string;
  trackLabel?: string;
  content?: string;
  handleClose?: any;
}) => {
  const { t } = useTranslation();
  const matomo = useMatomo();

  const handleCopy = () => {
    if (trackCopyEventPage) {
      matomo.trackEvent({
        category: trackCopyEventPage,
        action: trackAction || "Action undefined. Please contact support.",
        label: trackLabel,
      });
    }
    if (content) {
      navigator.clipboard.writeText(content);
    }
  };

  return (
    <Box justifyContent={"flex-end"} display={"flex"} width={"100%"} mt={4}>
      {content && (
        <Button sx={{ mx: 2 }} onClick={handleCopy} variant="contained">
          {t("modalButtons.copyButton")}
        </Button>
      )}
      {handleClose && (
        <Button onClick={() => handleClose(false)} variant="outlined">
          {t("modalButtons.closeButton")}
        </Button>
      )}
    </Box>
  );
};
