import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet, useNavigate } from "react-router-dom";
import LoadingAuth from "../LoadingAuth";
import {
  CAREER_SYNC,
  DASHBOARD,
  HOME,
  REGISTER_DETACHED,
} from "../../routes/paths";
import { getPersonStatus, getUser } from "../../api/hooks/careerRoadmap";
import toast from "react-hot-toast";

import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../../utils/local-storage";
import { PersonStatus } from "../../@types/api/careerRoadmap";
import { isNotEmpty } from "../../utils/helpers/isNotEmpty";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  const { data: { data: userData = {} as any } = {}, isError: userDataError } =
    getUser(!!authState?.isAuthenticated);

  const {
    data: { data: personStatus = {} as PersonStatus } = {},
    isError: personStatusError,
  } = getPersonStatus(userData?.status === "ACTIVE");

  useEffect(() => {
    if (!authState) {
      const oktaTokenStorage = getFromLocalStorage("okta-token-storage");
      if (!oktaTokenStorage || oktaTokenStorage === "{}") {
        window.location.href = HOME; // Should use vanilla JS here to refresh the page
        return;
      }
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  useEffect(() => {
    if (userDataError || personStatusError) {
      oktaAuth
        .signOut()
        .then(() => {
          navigate(HOME);
        })
        .catch(() => {
          toast.error(`Something went wrong while logging out`);
          removeFromLocalStorage("okta-shared-transaction-storage");
          removeFromLocalStorage("okta-token-storage");
          removeFromLocalStorage("okta-original-uri-storage");
          removeFromLocalStorage("okta-shared-transaction-storage");
          removeFromLocalStorage("okta-cache-storage");
          removeFromLocalStorage("ULT-USER-TOKEN");
          window.location.href = HOME; // Should use vanilla JS here to refresh the page
          return;
        });
    }
  }, [userDataError, personStatusError]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData?.status === "DETACHED") {
      navigate(REGISTER_DETACHED);
      return;
    }
  }, [JSON.stringify(userData)]);

  const shouldNavigateToDashboard = (personStatus: PersonStatus) => {
    const { curriculum, enrichedData } = personStatus;

    return enrichedData?.exist || curriculum?.exist;
  };

  const isPersonStatusLoaded = (personStatus: PersonStatus) => {
    const { curriculum, enrichedData } = personStatus;
    return isNotEmpty(enrichedData) && isNotEmpty(curriculum);
  };

  useEffect(() => {
    if (userData?.status === "ACTIVE") {
      if (isPersonStatusLoaded(personStatus)) {
        if (shouldNavigateToDashboard(personStatus)) {
          navigate(DASHBOARD);
          return;
        } else if (!shouldNavigateToDashboard(personStatus)) {
          // CarrerSync page will decide if user should go to DASHBOARD OR CURRICULUM_UPLOAD
          navigate(CAREER_SYNC);
          return;
        }
      }
    }
  }, [JSON.stringify(userData), JSON.stringify(personStatus)]);

  // Determines whether or not the user data is available before rendering the page
  const shouldWaitLoadUserData =
    // Check if the user is not authenticated
    (!authState ||
      !authState?.isAuthenticated ||
      // Check if the user data is not available
      !userData) &&
    // Check if the person status object is not available and the current page is not the detached registration page
    !(
      !isNotEmpty(personStatus) &&
      window.location.pathname === REGISTER_DETACHED
    );

  if (shouldWaitLoadUserData) {
    return <LoadingAuth />;
  }

  return <Outlet />;
};
