import styled from "@emotion/styled";
import { Card } from "../../Card";

export const CardPageContainer = styled(Card)({
  width: "396px", // 396px + (2x 50px padding) = 446px (max width). Don't change this value.
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 1px 38px #0000001A",
  borderRadius: "12px",
  padding: "50px",
  margin: "60px 0 0 0",
});
