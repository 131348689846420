import { Modal as MuiModal } from "@mui/material";
import { ModalCard } from "./styles";

const Modal = ({
  handleClose = () => console.log("handleClose"),
  open = false,
  children,
  width = "384px",
}: {
  handleClose?: any;
  open?: boolean;
  children?: any;
  width?: string;
}) => {
  return (
    <MuiModal open={open} onClose={handleClose}>
      <ModalCard width={width}>{children}</ModalCard>
    </MuiModal>
  );
};

export default Modal;
