import { Helmet } from "react-helmet";
import { CardPageContainer } from "./styles";

function CardPage({ children, title = "" }: { children?: any; title: string }) {
  return (
    <CardPageContainer>
      <Helmet title={`CareerRoadmap${title && ` - ${title}`}`} />
      {children}
    </CardPageContainer>
  );
}

export default CardPage;
