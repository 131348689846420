import { Box, Typography } from "@mui/material";
import { ReactComponent as AccessPassportLogo } from "../../assets/accesspassport_logo.svg";
import { useTranslation } from "react-i18next";

function AccessPassportFooter() {
  const { t } = useTranslation();

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      mt={7}
      height={"35px"}
      width={"100%"}
    >
      <Typography mr={"8px"} variant="body2">
        {t("accessPassportFooter.authBy")}
      </Typography>
      <AccessPassportLogo style={{ height: "35px" }} />
    </Box>
  );
}

export default AccessPassportFooter;
