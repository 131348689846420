import { Box } from "@mui/material";
import { TextArea } from "../../../TextArea";
import { ModalButtons } from "../../../ModalButtons";
import { ModalHeader } from "../../../ModalHeader";
import { actions, pages } from "../../../../config/matomo/matomo";
import { COVER_LETTER } from "../../HelpMeGetThisJob";

interface CoverLetterAndJobQuestionsProps {
  type: string;
  title: string;
  position: string;
  content: string;
  handleClose?: any;
}

function CoverLetterAndJobQuestions({
  type,
  title,
  position,
  content,
  handleClose,
}: CoverLetterAndJobQuestionsProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="636px"
    >
      <ModalHeader
        mb="17"
        handleClose={handleClose}
        title={title}
        subtitle={position}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        {content && (
          <TextArea
            variant="body2"
            backgroundType="light"
            mt={"25px"}
            maxHeight={"440px"}
          >
            {content}
          </TextArea>
        )}

        <ModalButtons
          handleClose={handleClose}
          content={content}
          trackCopyEventPage={pages.DASHBOARD_PAGE}
          trackAction={
            type === COVER_LETTER
              ? actions.ACTION_CLICK_COVER_LETTER_COPY
              : actions.ACTION_CLICK_INTERVIEW_QUESTIONS_COPY
          }
          trackLabel={position}
        />
      </Box>
    </Box>
  );
}

export default CoverLetterAndJobQuestions;
