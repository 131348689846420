import { TextField, Typography } from "@mui/material";
import { useField } from "formik";

interface TextInputProps {
  label: string;
  fieldName: string;
  type: string;
  disabled?: boolean;
  initialValue?: string;
  multiline?: boolean;
  maxRows?: number;
}

const TextInput = ({
  type,
  label,
  initialValue,
  disabled,
  fieldName,
  multiline = false,
  maxRows = 1,
}: TextInputProps) => {
  const [field, meta] = useField(fieldName);
  return (
    <>
      <TextField
        type={type}
        label={label}
        defaultValue={initialValue}
        {...field}
        fullWidth
        variant={"outlined"}
        size="small"
        style={{ marginTop: "20px" }}
        disabled={disabled}
        multiline={multiline}
        maxRows={maxRows}
      />
      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          mt={"5px"}
          lineHeight={"20px"}
          style={{ color: "red" }}
        >
          {" "}
          {meta.error}{" "}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

export default TextInput;
