import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const DefaultLayoutView = styled(Box)({});

export const DefaultLayoutContent = styled(Box)({
  paddingTop: "64px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});
