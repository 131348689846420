import { Outlet } from "react-router";
import { DefaultLayoutContent, DefaultLayoutView } from "./styles";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

function Layout() {
  return (
    <DefaultLayoutView>
      <NavBar />
      <DefaultLayoutContent>
        <Outlet />
      </DefaultLayoutContent>
      <Footer />
    </DefaultLayoutView>
  );
}

export default Layout;
