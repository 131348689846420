import { Box, LinearProgress, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";
import CardPage from "../../components/CardPage";
import { useTranslation } from "react-i18next";

import {
  getPersonStatus,
  postFileUploadUrlCurriculum,
  postPersonCurriculumLoad,
} from "../../api/hooks/careerRoadmap";
import { toast } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { useState } from "react";
import axios from "axios";

import { ReactComponent as PDFIcon } from "../../assets/pdf_icon.svg";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useNavigate } from "react-router";
import { DASHBOARD } from "../../routes/paths";

const CurriculumUploadPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: postFileUploadUrlCurriculumMutation } =
    postFileUploadUrlCurriculum();

  const { mutateAsync: PostPersonCurriculumLoadMutation } =
    postPersonCurriculumLoad();

  const { refetch: refetchPersonStatus } = getPersonStatus(false);

  const [progress, setProgress] = useState<{ name: string; percent: number }>({
    name: "",
    percent: 0,
  });

  const [fileTypeValid, setFileTypeValid] = useState<boolean>(true);

  const [preparingToUpload, setPreparingToUpload] = useState<{
    name: string;
    preparing: boolean;
  }>({ name: "", preparing: false });

  const [analyzingCV, setAnalyzingCV] = useState<boolean>(false);

  const onDropAccepted = async (files: File[]) => {
    setProgress({ name: files[0].name, percent: 0 });

    try {
      let url = "";
      try {
        setPreparingToUpload({ name: files[0].name, preparing: true });
        const { data } = await postFileUploadUrlCurriculumMutation();
        url = data.url || "";
      } catch (error) {
        toast.error(t("curriculumUpload.preparingUploadError"));
      }

      if (url) {
        const config = {
          onUploadProgress: (progressEvent: any) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress({ name: files[0].name, percent });
          },
          headers: {
            "Content-Type": "application/pdf",
          },
        };

        try {
          await axios.put(url, files[0], config);
          setAnalyzingCV(true);
          await PostPersonCurriculumLoadMutation();
          await refetchPersonStatus();
          toast.success(t("curriculumUpload.uploadSuccessMessage"));
          navigate(DASHBOARD);
        } catch (error) {
          toast.error(t("curriculumUpload.uploadError"));
        } finally {
          setAnalyzingCV(false);
          setPreparingToUpload({ name: "", preparing: false }); // Only remove preparing here so it doesn't flicker
        }
      }
    } catch (error) {
      toast.error(t("curriculumUpload.uploadError"));
    }
  };

  const onDropRejected = () => {
    setFileTypeValid(true);
    toast.error(t("curriculumUpload.rejectedFileTypeError"));
  };

  const onDragOver = (event: any) => {
    const fileType = event.dataTransfer.items[0].type;
    setFileTypeValid(fileType === "application/pdf");
    event.preventDefault();
  };

  const onDragLeave = (event: any) => {
    setFileTypeValid(true);
    event.preventDefault();
  };

  return (
    <CardPage title={t("curriculumUpload.tabName")}>
      <LogoSimple />

      <Typography fontWeight={300} mt={4} variant="h5">
        {t("curriculumUpload.title")}
      </Typography>

      <Typography mt={"20px"} fontWeight={600} variant="h6">
        {t("curriculumUpload.subtitle")}
      </Typography>

      <Box
        height={1}
        mt={4}
        mb={2}
        width={"60%"}
        borderBottom={"1px solid #E0E0E0"}
      />

      <Typography mt={"14px"} mb={"20px"} fontWeight={600}>
        {t("curriculumUpload.body")}
      </Typography>

      <Dropzone
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        multiple={false}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        accept={{ "application/pdf": [".pdf"] }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              style: {
                borderRadius: "9px",
                border: fileTypeValid ? "2px dashed #E0E0E0" : "2px dashed red",
                backgroundColor: fileTypeValid ? "#FFFFFF" : "#FFEBEE",
                padding: "32px",
                width: "80%",
              },
            })}
          >
            <input {...getInputProps()} />
            {progress.percent === 0 && !preparingToUpload.preparing && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CloudUploadOutlinedIcon
                  style={{ color: "#707070" }}
                  fontSize={"large"}
                />
                {isDragActive ? (
                  <>
                    <Typography>
                      {fileTypeValid
                        ? t("curriculumUpload.dropFileHere")
                        : t("curriculumUpload.onlyPdfAccepted")}
                    </Typography>
                    <Typography>
                      {/* Added so when invalid file is dragged, it doenst change the box size */}
                      <br />
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      {t("curriculumUpload.dragAndDropOrChoose")}
                    </Typography>
                    <Typography>
                      <em>{t("curriculumUpload.onlyPdfAcceptedNote")}</em>
                    </Typography>
                  </>
                )}
              </Box>
            )}

            {(progress.percent > 0 || preparingToUpload.preparing) && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"nowrap"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <PDFIcon />
                <Box ml={2} flexGrow={1}>
                  <Typography mb={"6px"}>
                    {progress.name || preparingToUpload.name}
                  </Typography>

                  {/* eslint-disable prettier/prettier */}
                  <LinearProgress
                    color="success"
                    variant={
                      (preparingToUpload.preparing && progress.percent === 0) ||
                        analyzingCV
                        ? "indeterminate"
                        : "determinate"
                    }
                    value={progress.percent}
                  />
                  {/* eslint-enable prettier/prettier */}

                  <Typography mt={"6px"}>
                    {/* eslint-disable prettier/prettier */}
                    {(preparingToUpload.preparing && progress.percent === 0) || analyzingCV
                      ? !analyzingCV
                        ? t("curriculumUpload.preparingUpload")
                        : t("curriculumUpload.analyzingCV")
                      : progress.percent > 0 && progress.percent !== 100
                        ? t("curriculumUpload.uploading")
                        : t("curriculumUpload.uploadSuccess")}
                    {/* eslint-enable prettier/prettier */}
                  </Typography>
                </Box>
              </Box>
            )}
          </div>
        )}
      </Dropzone>
    </CardPage>
  );
};

export default CurriculumUploadPage;
