import Modal from "../../Modal";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import { useEffect, useState } from "react";
import CoverLetterAndJobQuestions from "./CoverLetterAndJobQuestions";
import {
  getCareer,
  postCoverLetter,
  postInterviewQuestions,
} from "../../../api/hooks/careerRoadmap";
import { toast } from "react-hot-toast";
import { ModalHeader } from "../../ModalHeader";
import useMatomo, { actions, pages } from "../../../config/matomo/matomo";

export const COVER_LETTER = "coverLetter";
const HelpMeGetThisJobModal = ({
  open = false,
  handleClose = () => console.log("handleClose"),
  milestoneJobTitle = "",
  coverLetter = "",
  interviewQuestions = "",
}: {
  open?: boolean;
  handleClose?: any;
  milestoneJobTitle: string;
  coverLetter: string;
  interviewQuestions: string;
}) => {
  const matomo = useMatomo();
  const pageCategory = pages.DASHBOARD_PAGE;
  const { t } = useTranslation();
  const MODAL_ROOT = "modalRoot";
  const JOB_INTERVIEW = "jobInterview";
  const [modalContent, setModalContent] = useState(MODAL_ROOT);

  const [loadingCoverLetter, setLoadingCoverLetter] = useState(false);
  const [loadingJobInterviewQuestions, setLoadingJobInterviewQuestions] =
    useState(false);

  useEffect(() => {
    if (!open) {
      setModalContent(MODAL_ROOT);
    }
  }, [open]);

  const { mutateAsync: postCoverLetterMutation } = postCoverLetter();

  const { mutateAsync: postInterviewQuestionsMutation } =
    postInterviewQuestions();

  const { refetch: refetchCareer } = getCareer(false);

  const handleCoverLetterClick = async () => {
    matomo.trackEvent({
      category: pageCategory,
      action: actions.ACTION_CLICK_COVER_LETTER,
      label: milestoneJobTitle,
    });
    setLoadingCoverLetter(true);
    try {
      if (!coverLetter) {
        await postCoverLetterMutation();
        await refetchCareer();
      }
      setModalContent(COVER_LETTER);
    } catch (error) {
      toast.error(t("Something went wrong while generating cover letter"));
    } finally {
      setLoadingCoverLetter(false);
    }
  };

  const handleJobInterviewClick = async () => {
    matomo.trackEvent({
      category: pageCategory,
      action: actions.ACTION_CLICK_INTERVIEW_QUESTIONS,
      label: milestoneJobTitle,
    });
    setLoadingJobInterviewQuestions(true);
    try {
      if (!interviewQuestions) {
        await postInterviewQuestionsMutation();
        await refetchCareer();
      }
      setModalContent(JOB_INTERVIEW);
    } catch (error) {
      toast.error(
        t("Something went wrong while generating interview questions")
      );
    } finally {
      setLoadingJobInterviewQuestions(false);
    }
  };

  return (
    <Modal handleClose={() => handleClose(false)} open={open}>
      {modalContent === MODAL_ROOT && (
        <>
          <ModalHeader mb="0" handleClose={() => handleClose(false)} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"384px"}
          >
            <Typography variant="h6" mb={5}>
              {t("helpMeGetThisJobModal.title")}
            </Typography>
            <Button
              fullWidth
              sx={{ mb: 3 }}
              variant="contained"
              loading={loadingCoverLetter || loadingJobInterviewQuestions}
              onClick={handleCoverLetterClick}
            >
              {t("helpMeGetThisJobModal.coverLetterButton")}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              loading={loadingCoverLetter || loadingJobInterviewQuestions}
              onClick={handleJobInterviewClick}
            >
              {t("helpMeGetThisJobModal.jobInterviewButton")}
            </Button>
          </Box>
        </>
      )}
      {modalContent === COVER_LETTER && (
        <CoverLetterAndJobQuestions
          title={t("coverLetterModal.title")}
          position={milestoneJobTitle}
          content={coverLetter}
          handleClose={() => handleClose(false)}
          type={COVER_LETTER}
        />
      )}
      {modalContent === JOB_INTERVIEW && (
        <CoverLetterAndJobQuestions
          title={t("interviewQuestionsModal.title")}
          position={milestoneJobTitle}
          content={interviewQuestions}
          handleClose={() => handleClose(false)}
          type={JOB_INTERVIEW}
        />
      )}
    </Modal>
  );
};

export default HelpMeGetThisJobModal;
