import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as JobIcon } from "../../../../assets/careers_icon.svg";
import { Line } from "../../../../components/Line";
import { Card } from "../../../../components/Card";
import { Skeleton } from "../../../../components/Skeleton";
import { Button } from "../../../../components/Button";

export const MilestoneCard = ({
  isLoading = false,
  jobTitle,
  skills = [],
  onClickHelpMeGetThisJob = () => {
    console.log("onClickHelpMeGetThisJob");
  },
}: {
  isLoading?: boolean;
  jobTitle?: string;
  skills?: string[];
  onClickHelpMeGetThisJob: any;
}) => {
  const { t } = useTranslation();

  const fakeSkills = [
    "React",
    "React Native",
    "Node.js",
    "TypeScript",
    "JavaScript",
  ];

  return (
    <Box sx={{ position: "relative" }}>
      {!(skills.length > 0 && !!jobTitle) && (
        <>
          <Box
            sx={{
              width: "calc(236px - 86px)",
              height: "261px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 1px 4px #00000046",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              paddingX: "43px",
            }}
          >
            <JobIcon />
            <Typography sx={{ textAlign: "center", mt: "20px" }}>
              <Trans
                i18nKey="milestone.selectJobRole"
                components={{ 1: <b />, 2: <br /> }}
              >
                {`placeholder <2/><1>{{ firstName }}</1> placeholder`}
              </Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "360px",
              height: "550px",
              borderRadius: "12px",
              zIndex: 1,
              backdropFilter: "blur(5px)",
              "-webkit-backdrop-filter": "blur(5px)",
            }}
          />
        </>
      )}

      <Card
        sx={{
          alignItems: "flex-start !important",
          width: "calc(360px - 60px)",
          height: "calc(550px - 48px)",
          padding: "24px 30px",
        }}
      >
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          <JobIcon />
          <Typography fontWeight={"light"} variant="h6" ml={"7px"}>
            {t("milestone.title")}
          </Typography>
        </Box>

        <Typography
          textTransform={"capitalize"}
          mt={"30px"}
          fontWeight={"bold"}
          variant="h6"
        >
          {jobTitle ? jobTitle : t("milestone.fakeJobTitle")}
        </Typography>
        <Button
          onClick={onClickHelpMeGetThisJob}
          disabled={isLoading}
          sx={{ ml: "-8px" }}
        >
          {t("milestone.helpMeGetThisJob")}
        </Button>

        <Line width={"360px"} ml={"-30px"} mt={"32px"} mb={"30px"}></Line>
        {isLoading ? (
          <Skeleton width={"100%"} height={"100%"} sx={{ transform: "none" }} />
        ) : (
          <>
            <Typography fontSize={"12px"} fontWeight={"light"} mb={"21px"}>
              {t("milestone.skills")}
            </Typography>

            {[
              ...(skills.length > 0 && jobTitle ? [] : fakeSkills),
              ...skills,
            ].map((skill: string, index: number) => (
              <Box width={"100%"} key={skill}>
                <Typography textTransform={"capitalize"}>{skill}</Typography>
                {index !== skills.length - 1 && <Line my={"15px"}></Line>}
              </Box>
            ))}
          </>
        )}
      </Card>
    </Box>
  );
};
