import axios from "axios";

export const CareerRoadmapClient = async (
  url: string,
  method: string,
  Authorization: string | undefined = undefined,
  body?: any
) => {
  return axios({
    url,
    method,
    headers: {
      Authorization,
    },
    data: body,
  });
};
