// Unauthenticated Pages
export const HOME = "/";
export const LOGIN_CALLBACK = "/login/callback";
export const NOT_FOUND = "*";

// Authenticated Pages
export const CAREER_SYNC = "/career-sync";
export const EDIT_SUMMARY = "/edit-summary";
export const DASHBOARD = "/dashboard";
export const USER_DATA_NOT_FOUND = "/user-data-not-found";
export const REGISTER = "/register";
export const REGISTER_DETACHED = "/register-detached";
export const LOADING_AUTH = "/loading-auth";
export const CURRICULUM_UPLOAD = "/curriculum-upload";

// pages that shows the "My Profile" button in the navbar at the top-right position
export const ACTIVE_PROFILE_PAGES = [DASHBOARD];

// external links
export const TERMS_OF_SERVICE_LINK =
  "https://enterprisealumni.com/terms-of-service";
export const PRIVACY_POLICY_LINK = "https://enterprisealumni.com/privacy";
export const ABOUT_CAREERROADMAP_LINK =
  "https://enterprisealumni.com/careerroadmap";
export const ENTERPRISE_ALUMNI_LINK = "https://enterprisealumni.com/";

export const FEEDBACK_FORM_LINK =
  "https://enterprisealumni.outgrow.us/external-careerroadmap";
