import { CareerRoadmapClient } from "../client";

export class CareerRoadmapAPI {
  static baseUrl = process.env.REACT_APP_CAREER_ROADMAP_API_BASE_URL || "";

  static async postSummary(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/career/summary`,
      "POST",
      Authorization
    );
  }

  static async putSummary(Authorization?: string, data = {}) {
    return CareerRoadmapClient(
      `${this.baseUrl}/career/summary`,
      "PUT",
      Authorization,
      data
    );
  }

  static async getUser(Authorization?: string) {
    return CareerRoadmapClient(`${this.baseUrl}/user`, "GET", Authorization);
  }

  static async postUser(Authorization?: string, data = {}) {
    return CareerRoadmapClient(
      `${this.baseUrl}/user/register`,
      "POST",
      Authorization,
      data
    );
  }

  static async putUser(Authorization?: string, data = {}) {
    return CareerRoadmapClient(
      `${this.baseUrl}/user`,
      "PUT",
      Authorization,
      data
    );
  }

  static async getCareer(Authorization?: string) {
    return CareerRoadmapClient(`${this.baseUrl}/career`, "GET", Authorization);
  }

  static async postCareerSkills(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/career/skills`,
      "POST",
      Authorization
    );
  }

  static async postCareerNextMoves(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/career/next-moves`,
      "POST",
      Authorization
    );
  }

  static async putMilestone(Authorization?: string, data = {}) {
    const position = "0"; // TODO: Hardcoded for MVP
    return CareerRoadmapClient(
      `${this.baseUrl}/career/milestone/${position}`,
      "PUT",
      Authorization,
      data
    );
  }

  static async postSkillsToDevelop(Authorization?: string) {
    const position = "0"; // TODO: Hardcoded for MVP
    return CareerRoadmapClient(
      `${this.baseUrl}/career/milestone/${position}/skills/develop`,
      "POST",
      Authorization
    );
  }

  static async postInterviewQuestions(Authorization?: string) {
    const position = "0"; // TODO: Hardcoded for MVP
    return CareerRoadmapClient(
      `${this.baseUrl}/career/milestone/${position}/interview-questions`,
      "POST",
      Authorization
    );
  }

  static async postCoverLetter(Authorization?: string) {
    const position = "0"; // TODO: Hardcoded for MVP
    return CareerRoadmapClient(
      `${this.baseUrl}/career/milestone/${position}/cover-letter`,
      "POST",
      Authorization
    );
  }

  static async postCareerAdvice(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/career/advice`,
      "POST",
      Authorization
    );
  }

  static async getCompanies() {
    return CareerRoadmapClient(`${this.baseUrl}/company/list`, "GET");
  }

  static async getPersonStatus(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/person/status`,
      "GET",
      Authorization
    );
  }

  static async postPersonEnrich(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/person/enrich`,
      "POST",
      Authorization
    );
  }

  static async postFileUploadUrlCurriculum(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/file-upload/url/curriculum`,
      "GET", // I know, it's a GET on BE, but for me it's a POST
      Authorization
    );
  }

  static async postPersonCurriculumLoad(Authorization?: string) {
    return CareerRoadmapClient(
      `${this.baseUrl}/person/curriculum/load`,
      "POST",
      Authorization
    );
  }
}
