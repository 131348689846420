import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoSimple } from "../../assets/careerroadmap_logo_simple.svg";
import CardPage from "../../components/CardPage";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import AccessPassportFooter from "../../components/AccessPassportFooter";
import { Form, Formik } from "formik";
import TextInput from "../../components/TextInput";
import PrivacyPolicyCheckBox from "../../components/TermsConditionsCheckBox";
import { useOktaAuth } from "@okta/okta-react";
import { getUser, putUser } from "../../api/hooks/careerRoadmap";
import { CAREER_SYNC } from "../../routes/paths";
import { useNavigate } from "react-router";
import { PutUser } from "../../@types/api/careerRoadmap";
import { useEffect, useState } from "react";
import LoadingCard from "../../components/LoadingCard";
import CompanyComboBox from "../Register/components/CompanyComboBox";
import { schema } from "./schema";

const RegisterDetached = () => {
  const navigate = useNavigate();
  const putUserMutation = putUser();
  const { t } = useTranslation();
  const { authState } = useOktaAuth();

  const { data } = getUser(!!authState?.isAuthenticated);
  const { data: userData } = data || {};

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (putUserMutation.isError) {
      //TODO: error message for the user
      console.log(putUserMutation.failureReason);
    }
    if (putUserMutation.isSuccess) {
      navigate(CAREER_SYNC);
    }
  }, [putUserMutation]);

  return (
    <>
      {loading ? (
        <LoadingCard slug="register" />
      ) : (
        <CardPage title={t("register.tabName")}>
          <LogoSimple />

          <Typography fontWeight={300} mt={4} variant="h5">
            {t("register.title")}
          </Typography>

          <Typography mt={"20px"} fontWeight={600} variant="h6">
            {t("register.subtitle")}
          </Typography>

          <Box
            height={1}
            mt={4}
            width={"60%"}
            borderBottom={"1px solid #E0E0E0"}
          />

          <Formik
            initialValues={{
              company: "",
              acceptedPrivacyPolicy: false,
              firstName: userData?.firstName || "",
              lastName: userData?.lastName || "",
              emailAddress: userData?.email || "",
            }}
            validationSchema={() => schema(t)}
            enableReinitialize
            onSubmit={(values: any, { setSubmitting }: any) => {
              setLoading(true);
              const data: PutUser = {
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                ...values,
              };
              putUserMutation.mutate(data);
              setSubmitting(false);
            }}
          >
            <Form>
              <TextInput
                label={t("register.firstName")}
                fieldName="firstName"
                type="text"
                disabled={true}
              />

              <TextInput
                label={t("register.lastName")}
                fieldName="lastName"
                type="text"
                disabled={true}
              />

              <TextInput
                label={t("register.emailAddress")}
                fieldName="emailAddress"
                type="text"
                disabled={true}
              />

              <CompanyComboBox
                label={t("register.currentOrLastCompany")}
                fieldName="company"
              />

              <PrivacyPolicyCheckBox name="acceptedPrivacyPolicy" />

              <Button
                type="submit"
                loading={false}
                sx={{ mt: 5 }}
                fullWidth
                variant="contained"
              >
                {t("register.registerButton")}
              </Button>
            </Form>
          </Formik>

          <AccessPassportFooter />
        </CardPage>
      )}
    </>
  );
};

export default RegisterDetached;
